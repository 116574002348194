import React, { Component } from "react";
import Icon from "react-icons-kit";
import { FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from "reactstrap";
import { ic_expand_more } from "react-icons-kit/md/ic_expand_more";
import { search } from "react-icons-kit/icomoon/search";
import { ic_chevron_left, ic_chevron_right } from "react-icons-kit/md/";
import { Calendar } from "react-calendar";
import moment from "moment";
import { connect } from "react-redux";

import { api_Req, timeSlotData } from "common/services";
import { actions } from "../../../../redux/actions/actions";
import { HomeLayout, Title, Button, PopUp } from "components";
import { performanceAssesmentData, performanceSelection } from "../ultilities/questionData";

class EditAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelApptPopupVisible: false,
            calendarOverlay: false,
            calendarVisible: false,
            dropDownVisible: false,
            timeSlot: [],
            apptData: {},
            apptHistoryDetails: {},
            selectedTimeSlot: {
                label: "",
            },
            performanceAssesment: {
                question1: undefined,
                question2: undefined,
                question3: undefined,
                question4: undefined,
                question5: undefined,
                question6: undefined,
                question7: undefined,
                question8: undefined,
            },
        };
    }

    componentDidMount() {
        const { location, history } = this.props;
        if (!location.state) return history.push("appointmentcalendar");
        const apptData = location.state.apptData;

        console.log(apptData);
        this.setState({ apptData });
        this.requestApptTimeSlot(moment(apptData.start).format("YYYY-MM-DD"));
        this.requestApptDetails(apptData);
    }

    onToggleCancelApptPopupVisible = () => {
        this.setState({ cancelApptPopupVisible: !this.state.cancelApptPopupVisible });
    };

    cancelAppt = async () => {
        this.onToggleCancelApptPopupVisible();
        try {
            const payload = {
                Id: this.state.apptHistoryDetails.appointmentId,
            };
            const response = await api_Req.post.cancelAppt(payload);
            this.props.alertSuccess(response.data.message);
            this.props.history.push("appointmentcalendar");
        } catch (error) {
            this.props.alertError(error);
        }
    };

    handleSubmit = () => {
        const { history } = this.props;
        const {
            apptHistoryDetails: { rightSPH, rightCYL, leftSPH, leftCYL, remark, appointmentDate, slot, appointmentId },
            performanceAssesment: { question1, question2, question3, question4, question5, question6, question7, question8 },
        } = this.state;

        const payload = {
            Id: appointmentId,
            Slot: slot + 1,
            Date: moment(appointmentDate).format("YYYY-MM-DD"),
            RightSPH: rightSPH,
            RightCYL: rightCYL,
            RightADD: 0,
            LeftSPH: leftSPH,
            LeftCYL: leftCYL,
            LeftADD: 0,
            Remark: remark,
            Question1: question1 ? question1.value : "",
            Question2: question2 ? question2.value : "",
            Question3: question3 ? question3.value : "",
            Question4: question4 ? question4.value : "",
            Question5: question5 ? question5.value : "",
            Question6: question6 ? question6.value : "",
            Question7: question7 ? question7.value : "",
            Question8: question8 ? question8.value : "",
        };

        api_Req.put
            .editAppt(payload)
            .then((resp) => {
                history.push("appointmentcalendar");
                this.props.alertSuccess(resp.data.message);
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    handleDateChange = (date) => {
        this.requestApptTimeSlot(moment(date).format("YYYY-MM-DD"));
        this.setState((prevState) => ({
            ...prevState,
            apptHistoryDetails: {
                ...prevState.apptHistoryDetails,
                appointmentDate: date,
            },
        }));
    };

    toggleDate = () => {
        this.setState((prevState) => ({
            calendarVisible: !prevState.calendarVisible,
            calendarOverlay: !prevState.calendarOverlay,
        }));
    };

    requestApptDetails = (apptData) => {
        api_Req.get
            .editApptDetails(apptData.appointmentId)
            .then((resp) => {
                this.preFillData(resp.data);
                this.setTimeSlot(timeSlotData[resp.data.slot - 1]);
            })
            .catch((err) => {
                //err
                this.props.alertError(err);
            });
    };

    preFillData = (data) => {
        const performanceAssesment = {};
        data.assessmentList &&
            data.assessmentList.forEach((o, i) => {
                const key = `question${i + 1}`;
                if (!o) {
                    performanceAssesment[key] = undefined;
                } else {
                    performanceAssesment[key] = performanceSelection[o - 1];
                }
            });

        this.setState({
            apptHistoryDetails: data,
            performanceAssesment,
        });
    };

    requestApptTimeSlot = (date) => {
        const { history } = this.props;
        const payload = {
            Date: date,
        };
        api_Req.post
            .apptTimeSlot(payload)
            .then((resp) => {
                this.setState({
                    timeSlot: resp.data.slotsAvailable,
                });
            })
            .catch((err) => {
                history.push("appointmentcalendar");
                this.props.alertError(err);
            });
    };

    handleChange = (e) => {
        const value = e.currentTarget.value;
        const name = e.currentTarget.name;

        if ((name !== "remark" && value > 10) || (name !== "remark" && value < -10)) return;
        this.setState((prevState) => ({
            ...prevState,
            apptHistoryDetails: {
                ...prevState.apptHistoryDetails,
                [name]: value,
            },
        }));
    };

    setTimeSlot = (data) => {
        this.setState((prevState) => ({
            ...prevState,
            apptHistoryDetails: {
                ...prevState.apptHistoryDetails,
                slot: data.index,
            },
            selectedTimeSlot: data,
        }));
    };

    toggleDropdown = (i) => {
        const key = `dropDownVisible${i}`;
        this.setState((prevState) => ({
            [key]: !prevState[key],
        }));
    };

    dropdownSelect = (data, key) => {
        this.setState((prevState) => ({
            ...prevState,
            performanceAssesment: {
                ...prevState.performanceAssesment,
                [key]: { value: data.value, label: data.label },
            },
        }));
    };

    render() {
        const { timeSlot, calendarOverlay, calendarVisible, apptHistoryDetails, selectedTimeSlot, performanceAssesment, apptData } = this.state;

        return (
            <HomeLayout>
                {calendarOverlay ? <div id="overlay" onClick={this.toggleDate} /> : null}
                {calendarVisible ? (
                    <div className="create-appt-calendar">
                        <div className="calendar-wrapper">
                            <div className="appt-calendar">
                                <Calendar
                                    locale="US"
                                    minDate={new Date()}
                                    onChange={this.handleDateChange}
                                    value={apptHistoryDetails && apptHistoryDetails.appointmentDate ? moment(apptHistoryDetails.appointmentDate)._d : new Date()}
                                    prevLabel={<Icon icon={ic_chevron_left} size={20} className="calendar_button" />}
                                    nextLabel={<Icon icon={ic_chevron_right} size={20} className="calendar_button" />}
                                    prev2Label={null}
                                    next2Label={null}
                                />
                                <div className="timeselect_container">
                                    <p className="request-slot">{apptData.requestTime}</p>
                                    <div className="timeselect_title">
                                        <Title title="TIME SLOT" />
                                        {timeSlotData.map((data, i) => {
                                            const selectedTime = selectedTimeSlot && selectedTimeSlot.index === i ? "active" : "";
                                            return (
                                                <button key={`${data.index}_${i}_time_slot`} disabled={!timeSlot[i]} className={selectedTime} onClick={() => this.setTimeSlot(data)}>
                                                    {data.label}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="editappt_container">
                    <div className="editappt_header">
                        <Title title="EDIT APPOINTMENT" />
                        <p className="cancel-button bold" onClick={this.onToggleCancelApptPopupVisible}>
                            CANCEL APPOINTMENT
                        </p>
                    </div>
                    <div className="editappt_search">
                        <FormGroup>
                            <Label>Order Number</Label>
                            <div className="ordernum_container">
                                <Icon icon={search} className="icon_search" />
                                <Input className="ordernum_input" type="text" name="ordernum" placeholder="SEARCH" autoComplete="off" maxLength={20} value={`${apptHistoryDetails.orderNumber} ${apptHistoryDetails.patientName}`} disabled />
                            </div>
                            <div className="datetime_container">
                                <Label>Select Date and Time</Label>
                                <div className="date-input-wrapper" onClick={this.toggleDate}>
                                    <Icon icon={ic_expand_more} className="icon_chevron" size={25} />
                                    <Input className="datetime_input" value={`${moment(apptHistoryDetails.appointmentDate).format("DD/MM/YYYY")}  ${selectedTimeSlot.label}`} maxLength={20} readOnly />
                                </div>
                                <p className="request-slot">{apptData.requestTime}</p>
                            </div>

                            <div className="apptrecords_container">
                                <Title title="APPOINTMENT RECORDS" />
                                <div className="apptrecords_content">
                                    <Row className="apptrecords_row">
                                        <Col xs="1">RL TYPE</Col>
                                        <Col xs="2">
                                            <p>SPH*</p>
                                        </Col>
                                        <Col xs="2">
                                            <p>CYL*</p>
                                        </Col>
                                        <Col xs="2">AXIS*</Col>
                                    </Row>
                                    <Row className="apptrecords_row">
                                        <Col xs="1">RIGHT TYPE</Col>
                                        <Col xs="2">
                                            <Input min="-10" max="10" type="number" name="rightSPH" autoComplete="off" value={apptHistoryDetails.rightSPH || ""} onChange={this.handleChange} />
                                        </Col>
                                        <Col xs="2">
                                            <Input min="-10" max="10" type="number" name="rightCYL" autoComplete="off" value={apptHistoryDetails.rightCYL || ""} onChange={this.handleChange} />
                                        </Col>
                                        <Col xs="2">
                                            <Input min="-10" max="10" type="number" name="rightCYL" autoComplete="off" value={apptHistoryDetails.rightCYL || ""} onChange={this.handleChange} />
                                        </Col>
                                    </Row>
                                    <Row className="apptrecords_row">
                                        <Col xs="1">LEFT TYPE</Col>
                                        <Col xs="2">
                                            <Input min="-10" max="10" type="number" name="leftSPH" autoComplete="off" value={apptHistoryDetails.leftSPH || ""} onChange={this.handleChange} />
                                        </Col>
                                        <Col xs="2">
                                            <Input min="-10" max="10" type="number" name="leftCYL" autoComplete="off" value={apptHistoryDetails.leftCYL || ""} onChange={this.handleChange} />
                                        </Col>
                                        <Col xs="2">
                                            <Input min="-10" max="10" type="number" name="leftCYL" autoComplete="off" value={apptHistoryDetails.leftCYL || ""} onChange={this.handleChange} />
                                        </Col>
                                    </Row>
                                    <Row className="apptrecords_textarea">
                                        <Col xs="1">REMARKS</Col>
                                        <Col xs="2" md={{ size: 6 }}>
                                            <Input name="remark" type="textarea" autoComplete="off" placeholder="" value={apptHistoryDetails.remark || ""} onChange={this.handleChange} />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </FormGroup>
                    </div>
                    <div className="performance-assesment">
                        <Title title="PERFORMANCE ASSESSMENT" />
                        <ul className="performance-lists">
                            {performanceAssesmentData.map((q, i) => {
                                const key = `dropDownVisible${i}`;
                                const questionKey = `question${i + 1}`;
                                const performanceSelected = performanceAssesment && performanceAssesment[questionKey];
                                return (
                                    <li className="performance-item" key={`${i}_question`}>
                                        <p>{`${i + 1}. ${q.question}`}</p>
                                        <Dropdown isOpen={this.state[key]} toggle={() => this.toggleDropdown(i)}>
                                            <DropdownToggle caret className="toggle-btn">
                                                {performanceSelected ? performanceSelected.label : "SELECT"}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {performanceSelection.map((s, j) => {
                                                    return (
                                                        <DropdownItem key={`${j}_${s.label}`} onClick={() => this.dropdownSelect(s, `question${i + 1}`)}>
                                                            {s.label}
                                                        </DropdownItem>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="button_container">
                        <div className="button-group">
                            <Button title="WARRANTY REQUEST" onClick={() => this.props.history.push("/warranty-request", { apptData: apptHistoryDetails })} />
                        </div>
                        <div className="button-group">
                            <Button title="SAVE" onClick={this.handleSubmit} />
                            <Button title="CANCEL" outline onClick={this.props.history.goBack} />
                        </div>
                    </div>
                </div>

                <PopUp visible={this.state.cancelApptPopupVisible} toggle={this.onToggleCancelApptPopupVisible}>
                    <div className="cancel-appt-popup__container">
                        <Title title="CANCEL APPOINTMENT" />
                        <p className="cancel-appt-popup__text">Are you sure want to cancel appointment?</p>

                        <div className="cancel-appt-popup__button-container">
                            <Button title="CONFIRM" onClick={this.cancelAppt} />
                            <Button title="CANCEL" outline onClick={this.onToggleCancelApptPopupVisible} />
                        </div>
                    </div>
                </PopUp>
            </HomeLayout>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
});

export default connect(null, mapDispatchToProps)(EditAppointment);
